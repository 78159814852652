import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImageProps } from "gatsby-image";

interface StaticQueryResponse {
  image1: {
    childImageSharp: GatsbyImageProps;
  };
  image2: {
    childImageSharp: GatsbyImageProps;
  };
  wfhTeamplay: {
    childImageSharp: GatsbyImageProps;
  };
}

interface HookResponse {
  wfhTeamplay: GatsbyImageProps;
  image1: GatsbyImageProps;
  image2: GatsbyImageProps;
}

const useImageStaticQuery = (): HookResponse => {
  const {
    wfhTeamplay,
    image1,
    image2,
  } = useStaticQuery<StaticQueryResponse>(graphql`
    query {
      wfhTeamplay: file(
        relativePath: { eq: "components/news-section/images/wfh-teamplay.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 310, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image1: file(
        relativePath: { eq: "components/news-section/images/image-1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image2: file(
        relativePath: { eq: "components/news-section/images/image-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 530, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return {
    wfhTeamplay: wfhTeamplay.childImageSharp,
    image1: image1.childImageSharp,
    image2: image2.childImageSharp,
  };
};

export default useImageStaticQuery;
