import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImageProps } from "gatsby-image";

interface StaticQueryResponse {
  teamLiquid1: {
    childImageSharp: GatsbyImageProps;
  };
  teamLiquid2: {
    childImageSharp: GatsbyImageProps;
  };
  question1: {
    childImageSharp: GatsbyImageProps;
  };
  question2: {
    childImageSharp: GatsbyImageProps;
  };
  question3: {
    childImageSharp: GatsbyImageProps;
  };
  question4: {
    childImageSharp: GatsbyImageProps;
  };
}

interface HookResponse {
  teamLiquid1: GatsbyImageProps;
  teamLiquid2: GatsbyImageProps;
  question1: GatsbyImageProps;
  question2: GatsbyImageProps;
  question3: GatsbyImageProps;
  question4: GatsbyImageProps;
}

const useImageStaticQuery = (): HookResponse => {
  const {
    teamLiquid1,
    teamLiquid2,
    question1,
    question2,
    question3,
    question4,
  } = useStaticQuery<StaticQueryResponse>(graphql`
    query {
      teamLiquid1: file(
        relativePath: { eq: "components/faq-section/images/team-liquid-1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 530, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      teamLiquid2: file(
        relativePath: { eq: "components/faq-section/images/team-liquid-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      question1: file(
        relativePath: { eq: "components/faq-section/images/question-1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 90, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      question2: file(
        relativePath: { eq: "components/faq-section/images/question-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      question3: file(
        relativePath: { eq: "components/faq-section/images/question-3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      question4: file(
        relativePath: { eq: "components/faq-section/images/question-4.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 90, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return {
    teamLiquid1: teamLiquid1.childImageSharp,
    teamLiquid2: teamLiquid2.childImageSharp,
    question1: question1.childImageSharp,
    question2: question2.childImageSharp,
    question3: question3.childImageSharp,
    question4: question4.childImageSharp,
  };
};

export default useImageStaticQuery;
