import styled from "styled-components";
import Colors from "../../tokens/Colors";
import { mediaQueries } from "../../utils/mediaQueries";
import { rem } from "../../utils/rem";

const Paragraph = styled.p<{ color?: Colors }>`
  font-family: "FF Mark", arial, sans-serif;
  font-size: ${rem(14)};
  line-height: 1.875;
  letter-spacing: -0.5px;
  font-weight: normal;
  margin: 0 0 ${rem(30)};
  color: ${({ color = Colors.WHITE }) => color};

  ${mediaQueries.desktop} {
    margin: 0 0 ${rem(40)};
  }
`;

export default Paragraph;
