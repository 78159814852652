import GatsbyImage from "gatsby-image/index";
import React, { Fragment, FunctionComponent } from "react";
import styled from "styled-components";
import Container from "../../container/Container";
import { mediaQueries } from "../../utils/mediaQueries";
import { rem } from "../../utils/rem";
import Grid, { GridItem } from "../grid/Grid";
import OutlineTitle, { OutlineType } from "../outline-title/OutlineTitle";
import Question from "../question/Question";
import questions from "./data/questions.json";
import useImageStaticQuery from "./useImageStaticQuery";
import Img from "gatsby-image";

const FaqSection: FunctionComponent = () => {
  const { teamLiquid1, teamLiquid2, ...questionImages } = useImageStaticQuery();

  const questionImageMobileOffsets = [2, 3, 5, 2];
  const questionImageDesktopOffsets = [6, 2, 10, 4];
  const questionImageDesktopSpans = [1, 2, 2, 1];
  const desktopGridOrder = [1, 3, 2, 4, 5, 7, 6, 8];

  return (
    <StyledFaqSection>
      <Container>
        <StyledTopGrid>
          <GridItem
            start={{
              tablet: 1,
              desktop: 2,
            }}
            span={{
              mobile: 4,
              tablet: 5,
              desktop: 5,
            }}
          >
            <Img {...teamLiquid1} alt="team-liquid-1" />
          </GridItem>

          <StyledImage2GridItem
            start={{
              mobile: 4,
              tablet: 4,
              desktop: 9,
            }}
            span={{
              mobile: 3,
              tablet: 3,
              desktop: 2,
            }}
          >
            <Img {...teamLiquid2} alt="team-liquid-2" />
          </StyledImage2GridItem>
        </StyledTopGrid>
      </Container>

      <StyledHeading>
        <StyledOutlineTitleFirst outline={OutlineType.CONGRESS_BLUE}>
          Minds that
        </StyledOutlineTitleFirst>
        <StyledOutlineTitleSecond outline={OutlineType.CONGRESS_BLUE}>
          think alike
        </StyledOutlineTitleSecond>
      </StyledHeading>

      <StyledQuestionContainer>
        <StyledQuestionGrid>
          {questions.map((questionProps, index) => (
            <Fragment key={`question-${index}`}>
              <GridItem
                start={{
                  mobile: questionImageMobileOffsets[index],
                  tablet: questionImageMobileOffsets[index],
                  desktop: questionImageDesktopOffsets[index],
                }}
                span={{
                  mobile: 2,
                  tablet: 3,
                  desktop: questionImageDesktopSpans[index],
                }}
                order={{
                  desktop: desktopGridOrder[index * 2],
                }}
              >
                <StyledQuestionImage
                  {...questionImages[
                    `question${index + 1}` as keyof typeof questionImages
                  ]}
                  alt={questionProps.question}
                />
              </GridItem>
              <GridItem
                start={{
                  tablet: 0,
                  desktop: index % 2 === 0 ? 8 : 3,
                }}
                span={{
                  mobile: 6,
                  tablet: 6,
                  desktop: 3,
                }}
                order={{
                  desktop: desktopGridOrder[index * 2 + 1],
                }}
              >
                <Question {...questionProps} />
              </GridItem>
            </Fragment>
          ))}
        </StyledQuestionGrid>
      </StyledQuestionContainer>
    </StyledFaqSection>
  );
};

const StyledQuestionGrid = styled(Grid)`
  ${mediaQueries.desktop} {
    grid-row-gap: ${rem(50)};
  }
`;

const StyledTopGrid = styled(Grid)`
  ${mediaQueries.desktop} {
    align-items: flex-end;
  }
`;

const StyledOutlineTitleFirst = styled(OutlineTitle)`
  transform: translateX(-${rem(15)});
  margin: 0 0 ${rem(4)};

  ${mediaQueries.desktop} {
    margin: 0 0 ${rem(10)};
    transform: translateX(-${rem(190)});
  }
`;

const StyledOutlineTitleSecond = styled(OutlineTitle)`
  transform: translateX(${rem(15)});

  ${mediaQueries.desktop} {
    transform: translateX(${rem(190)});
  }
`;

const StyledFaqSection = styled.section`
  width: 100%;
  padding: 0 0 0;
  margin: -${rem(210)} 0 ${rem(260)};
  position: relative;
  overflow: hidden;

  ${mediaQueries.desktop} {
    margin: -${rem(650)} 0 ${rem(435)};
  }
`;

const StyledQuestionImage = styled(GatsbyImage)`
  margin: 0 0 ${rem(36)};
`;

const StyledImage2GridItem = styled(GridItem)`
  margin-top: ${rem(40)};
  position: relative;
  z-index: 1;

  ${mediaQueries.desktop} {
    margin-bottom: ${rem(120)};
  }
`;

const StyledHeading = styled.h1`
  margin: -${rem(17)} 0 0;

  ${mediaQueries.desktop} {
    margin-top: -${rem(74)};
  }
`;

const StyledQuestionContainer = styled(Container)`
  margin-top: -${rem(12)};
`;

export default FaqSection;
