import { AspectRatio } from "@codedazur/react-components";
import GatsbyImage, { GatsbyImageProps } from "gatsby-image/index";
import { darken } from "polished";
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useRef,
  useState,
} from "react";
import YouTube from "react-youtube";
import styled from "styled-components";
import Container from "../../container/Container";
import useBreakPoints from "../../hooks/use-breakpoints/useBreakPoints";
import Colors from "../../tokens/Colors";
import { mediaQueries } from "../../utils/mediaQueries";
import { rem } from "../../utils/rem";
import Icon, { Icons } from "../icon/Icon";
import OutlineTitle, { OutlineType } from "../outline-title/OutlineTitle";

interface VideoProps {
  youtubeEmbedId?: string;
  poster: GatsbyImageProps;
  title: string;
  className?: string;
  titleOutline: OutlineType;
  background?: Colors;
}

const Video: FunctionComponent<VideoProps> = ({
  poster,
  youtubeEmbedId,
  title,
  className,
  titleOutline,
  background,
}) => {
  const videoRef = useRef<YouTube>(null);
  const [showVideo, setShowVideo] = useState(false);

  const { isMobile, isTablet } = useBreakPoints();

  const onPosterClick = useCallback(() => {
    if (videoRef.current) {
      /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
      const player = videoRef.current.getInternalPlayer();
      player.playVideo();
      /* eslint-enable */

      setShowVideo(true);
    }
  }, []);

  return (
    <Fragment>
      <Container className={className}>
        <AspectRatio aspectRatio={16 / 9}>
          {!showVideo && (
            <StyledVideoPoster onClick={onPosterClick}>
              <StyledImage {...poster} style={{ position: "absolute" }} />
              <StyledPlayButton>
                <Icon
                  icon={Icons.PLAY_ICON}
                  width={isMobile || isTablet ? 8 : 12}
                />
              </StyledPlayButton>
            </StyledVideoPoster>
          )}

          <StyledYoutubePlayer
            videoId={youtubeEmbedId}
            ref={videoRef}
            show={showVideo}
            opts={{
              playerVars: {
                color: "white",
              },
            }}
          />
        </AspectRatio>
      </Container>
      <StyledTitleWrapper background={background}>
        <StyledHeading>
          <OutlineTitle outline={titleOutline}>{title}</OutlineTitle>
        </StyledHeading>
      </StyledTitleWrapper>
    </Fragment>
  );
};

const StyledHeading = styled.h1`
  width: 100%;
  position: relative;
  margin: 0;
  line-height: 1;
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

const StyledYoutubePlayer = styled(YouTube)<{ show: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  z-index: 2;
`;

const StyledVideoPoster = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const StyledPlayButton = styled.div`
  width: ${rem(80)};
  height: ${rem(70)};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${mediaQueries.desktop} {
    width: ${rem(140)};
    height: ${rem(120)};
  }

  > img {
    position: relative;
  }

  &:before {
    background: ${Colors.GREEN_YELLOW};
    transition: background-color 0.2s ease-in-out;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(10px 0%, 100% 0%, calc(100% - 10px) 100%, 0% 100%);

    ${mediaQueries.desktop} {
      clip-path: polygon(20px 0%, 100% 0%, calc(100% - 20px) 100%, 0% 100%);
    }
  }

  &:hover {
    &:before {
      background: ${darken(0.2, Colors.GREEN_YELLOW)};
    }
  }
`;

const StyledTitleWrapper = styled.div<{ background?: Colors }>`
  overflow: hidden;
  width: 100%;
  margin-top: -${rem(12)};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  background: ${({ background }) => background};

  ${mediaQueries.desktop} {
    margin-top: -${rem(42)};
  }
`;

export default Video;
