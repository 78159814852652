import { GatsbyImageProps } from "gatsby-image/index";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Colors from "../../tokens/Colors";
import Sizes from "../../tokens/Sizes";
import { rem } from "../../utils/rem";
import Button from "../button/Button";
import Grid, { GridItem } from "../grid/Grid";
import HighLight from "../highlight/Highlight";
import { Icons } from "../icon/Icon";
import Paragraph from "../paragraph/Paragraph";
import Title from "../title/Title";
import Img from "gatsby-image";

interface NewsItemProps {
  date?: string;
  title: string;
  hashtag?: string;
  description: string;
  image: GatsbyImageProps;
  url?: string;
}

const NewsItem: FunctionComponent<NewsItemProps> = ({
  title,
  description,
  date,
  image,
  url,
  hashtag,
}) => (
  <StyledArticle>
    <Grid>
      <GridItem
        start={{
          desktop: 3,
        }}
        span={{
          desktop: 3,
        }}
      >
        <StyledImageWrapper>
          <Img {...image} alt={title} />
        </StyledImageWrapper>
      </GridItem>
      <GridItem
        start={{
          desktop: 8,
        }}
        span={{
          desktop: 3,
        }}
      >
        {date && (
          <Title size={Sizes.SMALL} color={Colors.GREEN_YELLOW}>
            {date}
          </Title>
        )}

        <Title color={Colors.WHITE}>
          {title}{" "}
          {hashtag && (
            <HighLight color={Colors.GREEN_YELLOW}>{hashtag}</HighLight>
          )}
        </Title>

        <Paragraph>{description}</Paragraph>
        {url && (
          <Button href={url} icon={Icons.ARROW_RIGHT}>
            Contact for more info
          </Button>
        )}
      </GridItem>
    </Grid>
  </StyledArticle>
);

const StyledArticle = styled.article`
  position: relative;
`;

const StyledImageWrapper = styled.div`
  margin: 0 0 ${rem(36)};
  line-height: 0;
  position: relative;
`;

export default NewsItem;
