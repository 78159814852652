import React, { FunctionComponent, Fragment } from "react";
import styled from "styled-components";
import Background from "../components/background/Background";
import CareersSection from "../components/careers-section/CareersSection";
import FaqSection from "../components/faq-section/FaqSection";
import Hero from "../components/hero/Hero";
import NewsSection from "../components/news-section/NewsSection";
import { OutlineType } from "../components/outline-title/OutlineTitle";
import PressSection from "../components/press-section/PressSection";
import Video from "../components/video/Video";
import useImageStaticQuery from "../hooks/use-image-static-query/useImageStaticQuery";
import Colors from "../tokens/Colors";
import { mediaQueries } from "../utils/mediaQueries";
import { rem } from "../utils/rem";
import background1Bottom from "../images/background-1-bottom.svg";
import background1BottomMobile from "../images/background-1-bottom-mobile.svg";
import background3Top from "../images/background-3-top.svg";
import background3TopMobile from "../images/background-3-top-mobile.svg";
import background3Bottom from "../images/background-3-bottom.svg";
import background3BottomMobile from "../images/background-3-bottom-mobile.svg";

const Index: FunctionComponent = () => {
  const { introVideoPoster, joinVideoPoster } = useImageStaticQuery();

  return (
    <React.Fragment>
      <Background
        color={Colors.CONGRESS_BLUE}
        background={
          <StyledBackground
            mobile={background1BottomMobile}
            desktop={background1Bottom}
            position="bottom"
          />
        }
      >
        <Hero />
        <StyledIntroVideo
          poster={introVideoPoster}
          youtubeEmbedId={process.env.INTRO_YOUTUBE_EMBED_ID}
          title="Do the unthinkable"
          titleOutline={OutlineType.WHITE_GREEN_YELLOW_FADE}
        />
        <PressSection />
      </Background>
      <Background color={Colors.WHITE}>
        <FaqSection />
      </Background>
      <Background
        color={Colors.STRATOS}
        background={
          <Fragment>
            <StyledBackground
              mobile={background3TopMobile}
              desktop={background3Top}
              position="top"
            />
            <StyledBackground
              mobile={background3BottomMobile}
              desktop={background3Bottom}
              position="bottom"
              width={{
                mobile: "100%",
                desktop: "2640px",
              }}
            />
          </Fragment>
        }
      >
        <NewsSection />
      </Background>
      <StyledJoinVideo
        poster={joinVideoPoster}
        youtubeEmbedId={process.env.CAREERS_YOUTUBE_EMBED_ID}
        title="Join our team"
        titleOutline={OutlineType.GREEN_YELLOW_BONDI_BLUE_FADE}
      />
      <CareersSection />
    </React.Fragment>
  );
};

const StyledBackground = styled.div<{
  mobile: string;
  desktop: string;
  width?: {
    mobile: string;
    desktop: string;
  };
  position: "top" | "bottom";
}>`
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(${({ mobile }) => mobile});
  background-repeat: no-repeat;
  background-size: ${({ width }) => (width ? width.mobile : "100%")};
  background-position: ${({ position }) => position} center;
  top: 0;
  left: 0;
  pointer-events: none;

  ${mediaQueries.desktop} {
    background-size: ${({ width }) => (width ? width.desktop : "100%")};
    background-image: url(${({ desktop }) => desktop});
  }
`;

const StyledIntroVideo = styled(Video)`
  margin-top: -${rem(12)};

  ${mediaQueries.desktop} {
    margin-top: -${rem(125)};
  }

  ${mediaQueries.ultrawide} {
    margin-top: -${rem(180)};
  }
`;
const StyledJoinVideo = styled(Video)`
  margin-top: -${rem(130)};

  ${mediaQueries.desktop} {
    margin-top: -${rem(562)};
  }
`;

export default Index;
