import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Colors from "../../tokens/Colors";
import Sizes from "../../tokens/Sizes";
import Paragraph from "../paragraph/Paragraph";
import Title from "../title/Title";

interface QuestionProps {
  order: string;
  question: string;
  answer: string;
}

const Question: FunctionComponent<QuestionProps> = ({
  question,
  answer,
  order,
}) => (
  <StyledSection>
    <Title as="h3" size={Sizes.SMALL} color={Colors.BONDI_BLUE}>
      {order}
    </Title>
    <Title as="h2" color={Colors.CONGRESS_BLUE}>
      {question}
    </Title>
    <Paragraph color={Colors.DOVE_GRAY}>{answer}</Paragraph>
  </StyledSection>
);

const StyledSection = styled.section`
  overflow: hidden;
  position: relative;
`;

export default Question;
