import GatsbyImage from "gatsby-image/index";
import React, { FunctionComponent, useCallback, MouseEvent } from "react";
import styled from "styled-components";
import Container from "../../container/Container";
import useBreakPoints from "../../hooks/use-breakpoints/useBreakPoints";
import Colors from "../../tokens/Colors";
import Sizes from "../../tokens/Sizes";
import { mediaQueries } from "../../utils/mediaQueries";
import { rem } from "../../utils/rem";
import Header from "../header/Header";
import { Icons } from "../icon/Icon";
import TextButton from "../text-button/TextButton";
import Title from "../title/Title";
import useImageStaticQuery from "./useImageStaticQuery";

const Hero: FunctionComponent = () => {
  const { backgroundImage, backgroundImageMobile } = useImageStaticQuery();
  const { isMobile } = useBreakPoints();

  const backgroundImageProps = isMobile
    ? backgroundImageMobile
    : backgroundImage;

  const onScrollButtonClick = useCallback((event: MouseEvent) => {
    event.preventDefault();
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  }, []);

  return (
    <StyledWrapper>
      <StyledHeader />

      <StyledBackgroundWrapper>
        <StyledImage {...backgroundImageProps} alt="Team Liquid" />
      </StyledBackgroundWrapper>

      <StyledContentContainer>
        <Title as="h2" size={Sizes.SMALL} color={Colors.GREEN_YELLOW}>
          Do the unthinkable
        </Title>
        <StyledTitle>
          IMC and team Liquid join forces in an exchange of knowledge and skills
        </StyledTitle>
        <TextButton icon={Icons.ARROW_DOWN} onClick={onScrollButtonClick}>
          Scroll
        </TextButton>
      </StyledContentContainer>
    </StyledWrapper>
  );
};

const StyledContentContainer = styled(Container)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: ${rem(70)};
  padding-bottom: ${rem(80)};
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  max-width: ${rem(320)};

  ${mediaQueries.desktop} {
    padding-top: ${rem(120)};
    padding-bottom: 0;
    max-width: ${rem(686)};
    justify-content: center;
    height: ${rem(702)};
  }
`;

const StyledHeader = styled(Header)`
  position: absolute;
  top: 0;
  left: 0;
`;

const StyledImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  width: 100%;
`;

const StyledBackgroundWrapper = styled.div`
  width: calc(100% + ${rem(40)});
  position: relative;
  margin: 0 auto;
  transform: translateX(-20px);

  ${mediaQueries.desktop} {
    width: calc(100% + ${rem(140)});
    transform: translateX(-70px);
  }
`;

const StyledTitle = styled(Title)`
  ${mediaQueries.desktop} {
    font-size: ${rem(60)};
  }
`;

const StyledWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

export default Hero;
