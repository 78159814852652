import GatsbyImage from "gatsby-image/index";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Container from "../../container/Container";
import Colors from "../../tokens/Colors";
import Sizes from "../../tokens/Sizes";
import getUTMLink from "../../utils/getUTMLink";
import { mediaQueries } from "../../utils/mediaQueries";
import { rem } from "../../utils/rem";
import Button from "../button/Button";
import Grid, { GridItem } from "../grid/Grid";
import { Icons } from "../icon/Icon";
import Paragraph from "../paragraph/Paragraph";
import Title from "../title/Title";
import useImageStaticQuery from "./useImageStaticQuery";

const CareersSection: FunctionComponent = () => {
  const { background } = useImageStaticQuery();
  return (
    <StyledSection>
      <StyledWrapper>
        <Container>
          <Grid>
            <StyledGridItem
              start={{
                desktop: 4,
              }}
              span={{
                mobile: 6,
                tablet: 6,
                desktop: 6,
              }}
            >
              <Title color={Colors.GREEN_YELLOW} size={Sizes.SMALL} as="h2">
                Join us
              </Title>
              <StyledTitle color={Colors.CONGRESS_BLUE}>
                Come do the Unthinkable <br /> Join the team at IMC
              </StyledTitle>
              <StyledParagraph color={Colors.DOVE_GRAY}>
                At IMC we have redefined what is possible in the world of
                trading for over 30 years. Our partnership with Team Liquid is
                only one example of how we are continuing to advance the
                conversation. We are constantly on the lookout for exceptional
                talent to help us move forward in the world of market making and
                algorithmic trading - come join us!
              </StyledParagraph>

              <StyledButton
                href={getUTMLink("https://careers.imc.com", "careers-2")}
                icon={Icons.ARROW_UP_RIGHT}
              >
                Careers at IMC
              </StyledButton>
            </StyledGridItem>
          </Grid>
        </Container>

        <StyledImage
          {...background}
          alt="background image"
          draggable={false}
          style={{ position: "absolute" }}
        />
      </StyledWrapper>
    </StyledSection>
  );
};

const StyledSection = styled.section`
  background: ${Colors.WHITE};
`;

const StyledButton = styled(Button)`
  ${mediaQueries.desktop} {
    justify-content: center;
  }
`;

const StyledImage = styled(GatsbyImage)`
  bottom: 0;
  width: 100%;
  z-index: 0;
`;

const StyledGridItem = styled(GridItem)`
  margin: ${rem(80)} 0 ${rem(120)};
  text-align: center;
  position: relative;
  z-index: 1;

  ${mediaQueries.desktop} {
    margin: ${rem(195)} 0 ${rem(200)};
  }
`;

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledParagraph = styled(Paragraph)`
  max-width: ${rem(360)};
  display: inline-block;
`;

const StyledTitle = styled(Title)`
  ${mediaQueries.desktop} {
    font-size: ${rem(60)};
  }
`;

export default CareersSection;
