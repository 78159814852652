import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImageProps } from "gatsby-image";

interface StaticQueryResponse {
  introVideoPoster: {
    childImageSharp: GatsbyImageProps;
  };
  joinVideoPoster: {
    childImageSharp: GatsbyImageProps;
  };
}

interface HookResponse {
  introVideoPoster: GatsbyImageProps;
  joinVideoPoster: GatsbyImageProps;
}

const useImageStaticQuery = (): HookResponse => {
  const {
    introVideoPoster,
    joinVideoPoster,
  } = useStaticQuery<StaticQueryResponse>(graphql`
    query {
      introVideoPoster: file(
        relativePath: { eq: "images/intro-video-poster.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1300, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      joinVideoPoster: file(
        relativePath: { eq: "images/join-video-poster.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1300, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return {
    introVideoPoster: introVideoPoster.childImageSharp,
    joinVideoPoster: joinVideoPoster.childImageSharp,
  };
};

export default useImageStaticQuery;
