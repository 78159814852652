import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Container from "../../container/Container";
import Colors from "../../tokens/Colors";
import Sizes from "../../tokens/Sizes";
import { mediaQueries } from "../../utils/mediaQueries";
import { rem } from "../../utils/rem";
import Button from "../button/Button";
import Grid, { GridItem } from "../grid/Grid";
import { Icons } from "../icon/Icon";
import Paragraph from "../paragraph/Paragraph";
import Title from "../title/Title";

const PressSection: FunctionComponent = () => (
  <StyledContainer forwardedAs="section">
    <Grid>
      <GridItem
        start={{
          desktop: 8,
        }}
        span={{
          mobile: 6,
          tablet: 6,
          desktop: 3,
        }}
      >
        <Title as="h3" size={Sizes.SMALL} color={Colors.GREEN_YELLOW}>
          IMC partners with Team Liquid
        </Title>
        <Title as="h2">Do the unthinkable</Title>

        <Paragraph>
          You wouldn’t think that a trading company and an esports organisation
          have much in-common, but IMC and Team Liquid share the values of
          continuous learning, pushing the boundaries to break the barrier of
          individual performance and understanding that excellence is reached
          through investing in our people. By encouraging our employees to do
          the unthinkable, we will create a legacy - for our people, our
          companies and our industries.
        </Paragraph>

        <Paragraph>
          In a first-of-its-kind partnership, IMC and Team Liquid will work
          together to attract and foster new talent for their high-performance
          teams.
        </Paragraph>

        <Button
          href="/downloads/presskit-imc-x-team-liquid.zip"
          icon={Icons.ARROW_RIGHT}
        >
          Download press-kit
        </Button>
      </GridItem>
    </Grid>
  </StyledContainer>
);

const StyledContainer = styled(Container)`
  position: relative;
  margin: ${rem(80)} auto ${rem(330)};
  z-index: 1;

  ${mediaQueries.desktop} {
    margin: ${rem(165)} auto ${rem(440)};
  }
`;

export default PressSection;
