import styled from "styled-components";
import Colors from "../../tokens/Colors";

interface HighlightProps {
  color: Colors;
}

const HighLight = styled.span<HighlightProps>`
  color: ${({ color }) => color};
  display: block;
`;

export default HighLight;
