import React, { FunctionComponent } from "react";
import styled from "styled-components";
import useBreakPoints from "../../hooks/use-breakpoints/useBreakPoints";
import { mediaQueries } from "../../utils/mediaQueries";
import { rem } from "../../utils/rem";
import uniqueId from "lodash/uniqueId";

export enum OutlineType {
  WHITE_GREEN_YELLOW_FADE,
  CONGRESS_BLUE,
  WHITE,
  GREEN_YELLOW_BONDI_BLUE_FADE,
}

interface OutlineTitleProps {
  className?: string;
  outline: OutlineType;
  alignment?: "left" | "center";
}

const OutlineTitle: FunctionComponent<OutlineTitleProps> = ({
  children,
  className,
  outline,
  alignment = "center",
}) => {
  const { isMobile, isTablet } = useBreakPoints();
  const id = uniqueId();
  const xOffset = alignment === "center" ? "50%" : "0";

  return (
    <StyledSVG xmlns="http://www.w3.org/2000/svg" className={className}>
      <defs>
        {outline === OutlineType.WHITE_GREEN_YELLOW_FADE && (
          <linearGradient id={`gradient-${id}`} y1="0" y2="1">
            <stop stopColor="#ffffff" offset="0" />
            <stop stopColor="rgba(169, 251, 78, 0)" offset="1" />
          </linearGradient>
        )}

        {outline === OutlineType.GREEN_YELLOW_BONDI_BLUE_FADE && (
          <linearGradient id={`gradient-${id}`} y1="0" y2="1">
            <stop stopColor="rgba(169, 251, 78, 0.5)" offset="0" />
            <stop stopColor="#00B5B1" offset="1" />
          </linearGradient>
        )}

        {outline === OutlineType.CONGRESS_BLUE && (
          <linearGradient id={`gradient-${id}`} y1="0" y2="1">
            <stop stopColor="#005194" offset="0" />
            <stop stopColor="#005194" offset="1" />
          </linearGradient>
        )}

        {outline === OutlineType.WHITE && (
          <linearGradient id={`gradient-${id}`} y1="0" y2="1">
            <stop stopColor="#ffffff" offset="0" />
            <stop stopColor="#ffffff" offset="1" />
          </linearGradient>
        )}
      </defs>

      <StyledText
        id="text"
        stroke={`url(#gradient-${id})`}
        fill="none"
        x={xOffset}
        y={isMobile || isTablet ? 44 : 162}
        textAnchor={alignment === "center" ? "middle" : "left"}
      >
        {children}&nbsp;
        {/*Add extra space to prevent text clipping in safari*/}
      </StyledText>
    </StyledSVG>
  );
};

const StyledSVG = styled.svg`
  width: 100%;
  height: ${rem(46)};
  display: block;

  ${mediaQueries.desktop} {
    height: ${rem(166)};
  }
`;

const StyledText = styled.text`
  text-transform: uppercase;
  font-family: "Trade Gothic", Arial, sans-serif;
  font-style: oblique;
  font-size: ${rem(60)};
  stroke-width: ${rem(1)};
  font-weight: bold;
  line-height: 1;
  transform-origin: 0 0;
  width: 100%;

  ${mediaQueries.desktop} {
    font-size: ${rem(220)};
    stroke-width: ${rem(2)};
  }
`;

export default OutlineTitle;
