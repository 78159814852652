import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Container from "../../container/Container";
import { mediaQueries } from "../../utils/mediaQueries";
import { rem } from "../../utils/rem";
import Grid, { GridItem } from "../grid/Grid";
import NewsItem from "../news-item/NewsItem";
import OutlineTitle, { OutlineType } from "../outline-title/OutlineTitle";
import useImageStaticQuery from "./useImageStaticQuery";
import Img from "gatsby-image";

const NewsSection: FunctionComponent = () => {
  const { wfhTeamplay, image1, image2 } = useImageStaticQuery();

  return (
    <StyledContainer forwardedAs="section">
      <StyledGrid>
        <StyledImage1GridItem
          start={{
            mobile: 0,
            tablet: 0,
            desktop: 3,
          }}
          span={{
            mobile: 2,
            tablet: 2,
            desktop: 2,
          }}
        >
          <Img {...image1} alt="image 1" />
        </StyledImage1GridItem>
        <StyledImage2GridItem
          start={{
            mobile: 3,
            tablet: 3,
            desktop: 8,
          }}
          span={{
            mobile: 4,
            tablet: 4,
            desktop: 5,
          }}
        >
          <Img {...image2} alt="image 1" />
        </StyledImage2GridItem>
      </StyledGrid>
      <StyledTitle>
        <OutlineTitle outline={OutlineType.WHITE} alignment="left">
          Unthink
        </OutlineTitle>
        <StyledOutlineTitle
          outline={OutlineType.WHITE_GREEN_YELLOW_FADE}
          alignment="left"
        >
          Gaming
        </StyledOutlineTitle>
      </StyledTitle>

      <StyledNewsItemWrapper>
        <NewsItem
          title="Unthinkable hackathon"
          description="LIQUID HACKS was created to give the community a space to be innovative and to solve problems within the esports & gaming industry in the same way that Team Liquid has also been innovative in the space for the past 20 years. Starting in 2021, IMC will become a supporting partner of Liquid Hacks by helping to judge the hackathon entries, providing speakers and will add to the overall Prize Pool. More info will follow."
          image={wfhTeamplay}
        />
      </StyledNewsItemWrapper>
    </StyledContainer>
  );
};

const StyledGrid = styled(Grid)`
  margin-top: -${rem(190)};

  ${mediaQueries.desktop} {
    margin-top: -${rem(482)};
  }
`;

const StyledTitle = styled.h1`
  position: relative;
  overflow: hidden;
  margin: -${rem(12)} 0 ${rem(40)};

  ${mediaQueries.desktop} {
    margin: -${rem(200)} 0 ${rem(146)};
  }
`;

const StyledContainer = styled(Container)`
  margin-bottom: ${rem(75)};
`;

const StyledNewsItemWrapper = styled.div`
  margin: 0 0 ${rem(250)};

  ${mediaQueries.desktop} {
    margin: 0 0 ${rem(760)};
  }
`;

const StyledOutlineTitle = styled(OutlineTitle)`
  margin: ${rem(4)} 0 0 ${rem(50)};

  ${mediaQueries.desktop} {
    margin: ${rem(10)} 0 0 ${rem(220)};
  }
`;

const StyledImage1GridItem = styled(GridItem)`
  align-self: end;

  ${mediaQueries.desktop} {
    margin-bottom: ${rem(132)};
  }
`;

const StyledImage2GridItem = styled(GridItem)`
  margin-bottom: ${rem(90)};

  ${mediaQueries.desktop} {
    margin-bottom: 0;
  }
`;

export default NewsSection;
