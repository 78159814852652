import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImageProps } from "gatsby-image";

interface StaticQueryResponse {
  backgroundImage: {
    childImageSharp: GatsbyImageProps;
  };
  backgroundImageMobile: {
    childImageSharp: GatsbyImageProps;
  };
}

interface HookResponse {
  backgroundImage: GatsbyImageProps;
  backgroundImageMobile: GatsbyImageProps;
}

const useImageStaticQuery = (): HookResponse => {
  const {
    backgroundImage,
    backgroundImageMobile,
  } = useStaticQuery<StaticQueryResponse>(graphql`
    query {
      backgroundImage: file(
        relativePath: { eq: "components/hero/images/background-image.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      backgroundImageMobile: file(
        relativePath: {
          eq: "components/hero/images/background-image-mobile.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return {
    backgroundImage: backgroundImage.childImageSharp,
    backgroundImageMobile: backgroundImageMobile.childImageSharp,
  };
};

export default useImageStaticQuery;
