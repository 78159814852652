import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImageProps } from "gatsby-image";

interface StaticQueryResponse {
  background: {
    childImageSharp: GatsbyImageProps;
  };
}

interface HookResponse {
  background: GatsbyImageProps;
}

const useImageStaticQuery = (): HookResponse => {
  const { background } = useStaticQuery<StaticQueryResponse>(graphql`
    query {
      background: file(
        relativePath: { eq: "components/careers-section/images/background.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return {
    background: background.childImageSharp,
  };
};

export default useImageStaticQuery;
