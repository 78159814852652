import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Colors from "../../tokens/Colors";
import { mediaQueries } from "../../utils/mediaQueries";

interface BackgroundProps {
  color: Colors;
  background?: JSX.Element;
}

const Background: FunctionComponent<BackgroundProps> = ({
  children,
  color,
  background,
}) => (
  <StyledBackground>
    <StyledMask color={color}>{background}</StyledMask>
    {children}
  </StyledBackground>
);

const StyledBackground = styled.div`
  background: transparent;
  position: relative;
`;

const StyledMask = styled.div<{ color: Colors }>`
  position: absolute;
  left: 0;
  width: 100%;
  top: -70px;
  height: calc(100% + 70px);
  clip-path: polygon(0% 0%, 100% 70px, 100% 100%, 0% calc(100% - 70px));
  background: ${({ color }) => color};
  overflow: hidden;

  ${mediaQueries.tablet} {
    top: -160px;
    height: calc(100% + 160px);
    clip-path: polygon(0% 0%, 100% 160px, 100% 100%, 0% calc(100% - 160px));
  }

  ${mediaQueries.desktop} {
    top: -200px;
    height: calc(100% + 200px);
    clip-path: polygon(0% 0%, 100% 200px, 100% 100%, 0% calc(100% - 200px));
  }

  ${mediaQueries.ultrawide} {
    top: -280px;
    height: calc(100% + 280px);
    clip-path: polygon(0% 0%, 100% 280px, 100% 100%, 0% calc(100% - 280px));
  }
`;

export default Background;
